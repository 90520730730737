import axios from 'axios';
import { axiosConfig } from './axiosConfig';

const axiosConfigCatalogs =
    process.env.REACT_APP_ENV === 'development'
        ? axiosConfig('clients-api')
        : axiosConfig('client.sigrha.com');

export const getCatalog = async catalogName => {
    const { data } = await axios.get('/catalogs/catalogName', {
        params: { catalogName },
        ...axiosConfigCatalogs,
    });

    return data;
};

export const getAllCatalogs = async () => {
    const { data } = await axios.get('roles', axiosConfig('api'));

    return data;
};

export const getTypes = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            query: `query {
            types {
            id
            name
          }
          }`,
        }),
    }).then(res => {
        return res.json();
    });

    return data;
};

export const getCarouselData = async () => {
    const { data } = await fetch(`${process.env.REACT_APP_API}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            query: `query {
            content_type(type_id:3){
              id
              title
              description
              type {
                name
              }
              files {
                id
                name
                path
              }
            }
          }`,
        }),
    }).then(res => {
        return res.json();
    });

    return data;
};

export const getContentByType = async id => {
    const { data } = await fetch(`${process.env.REACT_APP_API}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            query: `query {
            content_type(type_id:${id}){
              id
              title
              description
              type {
                name
              }
              files {
                id
                name
                path
              }
            }
          }`,
        }),
    }).then(res => {
        return res.json();
    });

    return data;
};
