import { createTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const theme = createTheme(
    {
        palette: {
            primary: {
                main: 'rgb(121, 155, 157)',
            },
        },
        typography: {
            button: {
                fontWeight: 700,
            },
        },
    },
    esES
);

export default theme;
