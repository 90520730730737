import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../assets/images/logo_ortiz.png';
import { Link } from 'react-router-dom';

//Importaciones para la top bar
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { fetchTypes } from '../../features/catalogs/catalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING_STATUS } from '../../helpers/constants';
import ContactUs from '../../features/login/contactUs/ContactUs';

const useStyles = makeStyles(theme => ({
    rootTopBar: {
        background:
            'linear-gradient( transparent 50%, rgba(255, 255, 255, 0.2) 50%)',
        color: '#181818',
        position: 'absolute',
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    root: {
        flexGrow: 1,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    login: {
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    menus: {
        display: 'flex',
        marginRight: '30px',
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        marginTop: '30px',
        height: '80px',
        margin: '0 10px 0 30px',
    },
    item: {
        textDecoration: 'none',
        color: 'inherit',
        margin: 0,
        padding: 0,
        width: '100%',
    },
    sectionDesktop: {
        position: 'absolute',
        bottom: '5px',
        right: '70px',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        position: 'absolute',
        bottom: '5px',
        left: '10px',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    aButton: {
        color: 'rgba(255, 255, 255, 1)',
        textDecoration: 'none',
    },
    forScroll: {
        width: '10px',
        flexGrow: 1,
    },
    menuActive: {
        textDecoration: 'none',
        color: 'rgb(121, 155, 157)',
        borderBottom: '3px solid rgb(121, 155, 157)',
        borderRadius: '0',
    },
}));

export default function TopBarHome({ handleOpenMenu, openMenu }) {
    const [menuActive, setMenuActive] = useState(null);
    const [open, setOpen] = useState(false);
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.status);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setMenuActive(null);
    };

    const { isAuthenticated, error, logout } = useAuth0();
    const classes = useStyles();

    if (error) {
        logout();
        alert(`${error.message}`);
    }

    useEffect(() => {
        dispatch(fetchTypes());
    }, [dispatch]);

    const forActive = () => {
        const actualLocation = window.location.pathname.split('/');
        return actualLocation[1];
    };

    useEffect(() => {
        setMenuActive({
            text: forActive() ?? '',
        });
    }, [setMenuActive]);

    return (
        <>
            <div className={classes.body}>
                <div className={classes.forDialog}>
                    <ContactUs open={open} setOpen={e => setOpen(e)} />
                </div>
                <div className={classes.root}>
                    <AppBar className={classes.rootTopBar} position="fixed">
                        <Toolbar>
                            <div className={classes.sectionMobile}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={e => handleOpenMenu(e)}
                                    className={
                                        openMenu
                                            ? classes.menuButtonHidden
                                            : classes.menuButton
                                    }
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <div>
                                <Link to={'/'}>
                                    <img
                                        src={logo}
                                        alt=""
                                        className={classes.logo}
                                    />
                                </Link>
                            </div>
                            <div className={classes.sectionDesktop}>
                                <Button
                                    className={
                                        menuActive?.text === ''
                                            ? classes.menuActive
                                            : classes.aButton
                                    }
                                    onClick={e => {
                                        setMenuActive({
                                            text: '',
                                        });
                                    }}
                                >
                                    <Link
                                        className={classes.item}
                                        to={`/`}
                                        onClick={handleClose}
                                    >
                                        Inicio
                                    </Link>
                                </Button>
                                <Button
                                    className={
                                        menuActive?.text === 'empresa'
                                            ? classes.menuActive
                                            : classes.aButton
                                    }
                                    onClick={e => {
                                        setMenuActive({
                                            text: 'empresa',
                                        });
                                    }}
                                >
                                    <Link
                                        className={classes.item}
                                        to={`/empresa`}
                                        onClick={handleClose}
                                    >
                                        Empresa
                                    </Link>
                                </Button>
                                {catalogStatus === LOADING_STATUS.SUCCEEDED &&
                                    catalogs.types?.map(menu =>
                                        isAuthenticated ? (
                                            <>
                                                <Button
                                                    className={
                                                        menuActive?.text ===
                                                        menu.text
                                                            ? classes.menuActive
                                                            : classes.aButton
                                                    }
                                                    aria-controls="customized-menu"
                                                    aria-haspopup="true"
                                                    color="inherit"
                                                    onClick={e => {
                                                        setMenuActive({
                                                            text: menu.text,
                                                        });
                                                    }}
                                                >
                                                    <Link
                                                        className={classes.item}
                                                        to={`/${menu.text}/${menu.id}`}
                                                        onClick={handleClose}
                                                    >
                                                        {menu.text}
                                                    </Link>
                                                </Button>
                                            </>
                                        ) : (
                                            menu.text !== 'Carrusel' && (
                                                <>
                                                    <Button
                                                        className={
                                                            menuActive?.text ===
                                                            menu.text
                                                                ? classes.menuActive
                                                                : classes.aButton
                                                        }
                                                        aria-controls="customized-menu"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                        onClick={e => {
                                                            setMenuActive({
                                                                text: menu.text,
                                                            });
                                                        }}
                                                    >
                                                        <Link
                                                            className={
                                                                classes.item
                                                            }
                                                            to={`/${menu.text}/${menu.id}`}
                                                            onClick={
                                                                handleClose
                                                            }
                                                        >
                                                            {menu.text}
                                                        </Link>
                                                    </Button>
                                                </>
                                            )
                                        )
                                    )}
                                {!isAuthenticated && (
                                    <Button
                                        color="inherit"
                                        className={classes.aButton}
                                        onClick={() => handleClickOpen()}
                                    >
                                        ¡Contáctanos!
                                    </Button>
                                )}
                                {isAuthenticated && (
                                    <Button
                                        color="inherit"
                                        variant="contained"
                                        onClick={() => logout()}
                                    >
                                        Cerrar sesión
                                    </Button>
                                )}
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
            </div>
        </>
    );
}
