import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCarouselData } from '../../api/catalogs';
import { LOADING_STATUS } from '../../helpers/constants';
import { enqueueSnackbar } from '../../features/pushNotifications/pushNotificationsSlice';

const initialState = {
    data: [],
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchCarousel = createAsyncThunk(
    'carousel/fetchCarousel',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getCarouselData();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la información del carousel.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const carouselSlice = createSlice({
    name: 'carrousel',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchCarousel.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchCarousel.fulfilled, (state, { payload }) => {
                state.data = payload?.content_type ?? [];
                state.status = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(fetchCarousel.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = carouselSlice.actions;

export default carouselSlice.reducer;
