import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0.5),
    },
}));

const Footer = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <footer className={`${className} ${classes.footer}`} {...props}>
            <Typography
                variant="h6"
                align="center"
                gutterBottom
                style={{
                    color: 'rgb(255, 255, 255)',
                    fontWeight: 'bold',
                }}
            >
                Ortiz &reg; {new Date().getFullYear()}
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                component="p"
                style={{ color: 'rgb(255, 255, 255)' }}
            >
                Palería y mantenimiento industrial
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                component="p"
                style={{ color: 'rgb(255, 255, 255)' }}
            >
                contacto@paileriaortiz.com
            </Typography>
        </footer>
    );
};

export default Footer;
