import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TopBarHome from './common/TopBarHome/TopBarHome';
import SideNavigation from './common/SideNavigation/SideNavigation';
import Login from './features/login/login';
import { useState } from 'react';
import Notifier from './features/pushNotifications/Notifier';
import { SnackbarProvider } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './common/Loading/Loading';
import Company from './features/login/company/Company';
import SubjectInfo from './features/login/subjectInfo/SubjectInfo';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
    footer: {
        bottom: 0,
        textAlign: 'center',
    },
    leftSide: {
        background: 'white',
        height: '100%',
    },
    mainContent: {
        flexGrow: 1,
        padding: theme.spacing(1, 4, 4),
    },
    rightSide: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'static',
    },
    sideBar: {
        background: 'white',
        boxShadow: '-20px 0 20px 20px rgba(0,0,0,0.14)',
        height: '100%',
        minWidth: 270,
        width: '20%',
        position: 'fixed',
    },
    menuHidden: {
        display: 'none',
    },
}));

const App = () => {
    const {
        loginWithRedirect,
        isAuthenticated,
        user,
        isLoading,
        logout,
        error,
    } = useAuth0();
    const classes = useStyles();
    const [menuToggle, setMenuToggle] = useState(false);
    const menuToggleHandle = () => {
        setMenuToggle(!menuToggle);
    };
    const admins = [
        'tonyortiz181609@gmail.com',
        'oscar.daniel@frausto.mx',
        'luiserode.16@gmail.com',
    ];
    const isAdmin = user => {
        const found = admins.find(element => element === user);
        return found === undefined;
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isAuthenticated) {
        if (isAdmin(user.email)) {
            return logout();
        }
    }

    if (error) {
        logout();
        alert(`${error.message}`);
    }

    return (
        <Router basename={process.env.REACT_BASE_URL}>
            <SnackbarProvider
                autoHideDuration={7000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <div className={classes.root}>
                    <Notifier />
                    <Switch>
                        <div className={classes.rightSide}>
                            <div className={!menuToggle && classes.menuHidden}>
                                <SideNavigation
                                    menuToggle={menuToggle}
                                    className={classes.sideBar}
                                    menuToggleHandle={menuToggleHandle}
                                />
                            </div>
                            <TopBarHome
                                openMenu={menuToggle}
                                handleOpenMenu={e => setMenuToggle(e.isTrusted)}
                            />
                            <main>
                                <Switch>
                                    <Route exact path="/">
                                        <Login />
                                    </Route>
                                    <Route exact path="/empresa">
                                        <Company />
                                    </Route>
                                    <Route path="/:type/:id">
                                        <SubjectInfo />
                                    </Route>
                                    <Route path="/login">
                                        {loginWithRedirect}
                                    </Route>
                                </Switch>
                            </main>
                        </div>
                    </Switch>
                </div>
            </SnackbarProvider>
        </Router>
    );
};

export default App;
