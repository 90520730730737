import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { initialValues, validationSchema } from './dialogForm';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    miniPicture: {
        height: '100px',
        width: '100px',
    },
}));

const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = error => {
            reject(error);
        };
    });
};

const UpdateImage = ({
    handleClose,
    handleFileChange,
    open,
    type,
    onEdit = { ...initialValues },
}) => {
    const classes = useStyles();
    const [dialogFile, setDialogFile] = useState();
    const [base64File, setBase64File] = useState();

    const handleDialogFile = async e => {
        const file = e.target.files[0];
        const base64 = await convertBase64(file);
        setDialogFile(file);
        setBase64File(base64);
    };
    useEffect(() => {
        if (!open) {
            setDialogFile(undefined);
            setBase64File(undefined);
        }
    }, [open]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                Cargar {type}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={onEdit}
                    validationSchema={validationSchema}
                    onSubmit={({ fileName }) =>
                        handleFileChange({
                            file: dialogFile,
                            fileName,
                            id: Number(onEdit.id),
                        })
                    }
                >
                    {props => {
                        const { setFieldValue, values } = props;
                        const { fileName } = values;
                        if (dialogFile && fileName !== dialogFile.name) {
                            setFieldValue('fileName', dialogFile.name);
                        }
                        const validExtensions = () => {
                            if (fileName) {
                                let extensions = [];
                                const videoExt = ['mp4', 'avi', 'flv', 'mov'];
                                const imageExt = ['jpeg', 'jpg', 'png'];
                                if (type === 'imagen') {
                                    extensions = [...imageExt];
                                } else if (type === 'video') {
                                    extensions = [...videoExt];
                                }
                                const split = fileName.split('.');
                                function getExtension(ext) {
                                    let fileNameExt = split[split.length - 1];
                                    return ext === fileNameExt.toLowerCase();
                                }
                                return !extensions.some(getExtension);
                            } else {
                                return true;
                            }
                        };

                        return (
                            <Form>
                                <Grid
                                    justifyContent="center"
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    {base64File && (
                                        <img
                                            className={classes.miniPicture}
                                            src={base64File}
                                            alt="imagen"
                                        ></img>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            component="label"
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Buscar {type}
                                            <input
                                                type="file"
                                                className={classes.inputFile}
                                                onChange={handleDialogFile}
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            onClick={handleClose}
                                            variant="outlined"
                                            type="button"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            disabled={validExtensions()}
                                        >
                                            Cargar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateImage;
