import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

//menus
import CarouselComponent from '../../common/carousel/Carousel';
import { fetchCarousel } from '../../common/carousel/carouselSlice';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    body: {
        height: '100vh',
        backgroundColor: 'rgb(0, 0, 0)',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'fixed',
        color: 'white',
    },
    image: {
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(5, 4),
        padding: theme.spacing(5, 4, 1),
        position: 'absolute',
        top: '100px',
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        height: '70px',
        width: '70px',
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '60%',
        fontSize: '13px',
        backgroundColor: 'rgb(121, 155, 157)',
    },
    login: {
        backgroundColor: 'rgba(255,255,255,0.0)',
        boxShadow: 'none',
    },
    menus: {
        display: 'flex',
        marginRight: '30px',
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        height: '50px',
        margin: '0 10px 0 10px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    aButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
    },
    aButtonW: {
        color: 'rgba(255, 255, 255, 1)',
        textDecoration: 'none',
    },
    checkAvatar: {
        height: '70px',
        width: '70px',
        color: 'green',
    },
    info: {
        textAlign: 'center',
    },
}));

export default function Login() {
    const classes = useStyles();
    const carouselData = useSelector(state => state.carousel.data);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCarousel());
    }, [dispatch]);

    return (
        <>
            <div className={classes.body}>
                <CarouselComponent
                    data={carouselData}
                    className={classes.image}
                />
            </div>
        </>
    );
}
