import * as yup from 'yup';
import {
    maxCharMessage,
    requiredMessage,
} from '../../../helpers/validationSchemaHelpers';

export const initialValues = {
    title: '',
    description: '',
    file: '',
};

export const validationSchema = yup.object({
    title: yup.string().max(50, maxCharMessage).required(requiredMessage),
    description: yup
        .string()
        .max(500, maxCharMessage)
        .required(requiredMessage),
});
