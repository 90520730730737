import { configureStore } from '@reduxjs/toolkit';
import catalogsReducer from '../features/catalogs/catalogSlice';
import pushNotificationsReducer from '../features/pushNotifications/pushNotificationsSlice';
import contactUsReducer from '../features/login/contactUs/contactUsSlice';
import carouselReducer from '../common/carousel/carouselSlice';
import dataOfTypeReducer from '../features/login/subjectInfo/dataOfTypeSlice';

export const store = configureStore({
    reducer: {
        catalogs: catalogsReducer,
        pushNotifications: pushNotificationsReducer,
        contactMail: contactUsReducer,
        carousel: carouselReducer,
        dataOfType: dataOfTypeReducer,
    },
});
