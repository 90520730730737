import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { FastField, Form, Formik, getIn } from 'formik';
import { OutlinedTextField } from '../../../common/FormComponents/FormComponents';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { initialFormInfo, validationSchema } from './mainForm';
import { contactUsMail, resetValues } from './contactUsSlice';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LOADING_STATUS } from '../../../helpers/constants';
import Loading from '../../../common/Loading/Loading';
import CloseIcon from '@material-ui/icons/Close';
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: 'transparent',
    },
    field: {
        padding: theme.spacing(1),
    },
    forEdit: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1, 0, 0, 0),
    },
    paper: {
        margin: theme.spacing(1, 1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    info: {
        textAlign: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    checkAvatar: {
        height: '70px',
        width: '70px',
        color: 'green',
    },
    forLoading: {
        height: '50px',
    },
    dialogActions: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            height: '80px',
            flexDirection: 'column',
        },
    },
    whatsButton: {
        backgroundColor: 'transparent',
        border: 'none',
    },
    optionButtons: {
        justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            height: '150px',
            flexDirection: 'column',
        },
    },
}));

export default function ContactUs({ open, setOpen }) {
    const [send, setSend] = useState(LOADING_STATUS.IDLE);
    const [contactWay, setContactWay] = useState(null);
    const sendStatus = useSelector(state => state.contactMail.sendStatus);
    const classes = useStyles();
    const prevSubmitCount = useRef(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (sendStatus === LOADING_STATUS.SUCCEEDED) {
            setSend(LOADING_STATUS.SUCCEEDED);
        }
        if (sendStatus === LOADING_STATUS.LOADING) {
            setSend(LOADING_STATUS.LOADING);
        }
    }, [sendStatus]);

    const handleFieldUpdate = (nextProps, currentProps) => {
        if (
            nextProps.edit !== currentProps.edit ||
            nextProps.disabled !== currentProps.disabled ||
            nextProps.catalog !== currentProps.catalog ||
            nextProps.loading !== currentProps.loading ||
            nextProps.name !== currentProps.name ||
            getIn(nextProps.formik.values, currentProps.name) !==
                getIn(currentProps.formik.values, currentProps.name) ||
            getIn(nextProps.formik.errors, currentProps.name) !==
                getIn(currentProps.formik.errors, currentProps.name) ||
            getIn(nextProps.formik.touched, currentProps.name) !==
                getIn(currentProps.formik.touched, currentProps.name) ||
            Object.keys(currentProps).length !==
                Object.keys(nextProps).length ||
            nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => {
                setOpen(false);
                resetValues();
                setSend(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    type="button"
                    onClick={() => {
                        setOpen(false);
                        resetValues();
                        setSend(false);
                        setContactWay(null);
                    }}
                >
                    <CloseIcon />
                </Button>
            </DialogActions>

            {contactWay === 'mail' ? (
                send === LOADING_STATUS.SUCCEEDED ? (
                    <div className={classes.paper}>
                        <CheckCircleOutlineIcon
                            className={classes.checkAvatar}
                        />
                        <DialogTitle id="alert-dialog-title">
                            {'¡Correo enviado con éxito!'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                Gracias por tu mensaje, nos contactaremos
                                contigo via correo electrónico lo antes posible.
                            </DialogContentText>
                        </DialogContent>
                    </div>
                ) : send === LOADING_STATUS.LOADING ? (
                    <Loading customHeight="50vh">Enviando mensaje</Loading>
                ) : (
                    <>
                        <div className={classes.paper}>
                            <DialogTitle id="alert-dialog-title">
                                <Typography component="h1" variant="h4">
                                    ¡Envíanos un mensaje!
                                </Typography>
                            </DialogTitle>
                        </div>
                        <DialogContent fullWidth>
                            <DialogContentText
                                id="alert-dialog-description"
                                className={classes.info}
                            >
                                <Formik
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                    initialValues={initialFormInfo}
                                >
                                    {props => {
                                        const {
                                            errors,
                                            submitCount,
                                            values,
                                            setValues,
                                        } = props;
                                        if (
                                            submitCount !==
                                            prevSubmitCount.current
                                        ) {
                                            prevSubmitCount.current = submitCount;
                                            if (
                                                Object.keys(errors).length > 0
                                            ) {
                                                dispatch(
                                                    enqueueSnackbar({
                                                        message:
                                                            'Por favor llene todos los campos correctamente.',
                                                        options: {
                                                            key:
                                                                new Date().getTime() +
                                                                Math.random(),
                                                            variant: 'error',
                                                        },
                                                    })
                                                );
                                            }
                                        }
                                        return (
                                            <Form>
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    className={classes.field}
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    name="firstName"
                                                    type="text"
                                                    label="Nombre"
                                                    edit={true}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                />
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    className={classes.field}
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    name="lastName"
                                                    type="text"
                                                    label="Apellido"
                                                    edit={true}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                />
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    className={classes.field}
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    name="phone"
                                                    type="number"
                                                    label="Teléfono"
                                                    edit={true}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                />
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    className={classes.field}
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    name="email"
                                                    type="text"
                                                    label="Correo electrónico"
                                                    edit={true}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                />
                                                <FastField
                                                    component={
                                                        OutlinedTextField
                                                    }
                                                    className={classes.field}
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    multiline
                                                    maxRows={3}
                                                    name="body"
                                                    type="text"
                                                    label="Mensaje"
                                                    edit={true}
                                                    shouldUpdate={
                                                        handleFieldUpdate
                                                    }
                                                />

                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    type="button"
                                                    onClick={() => {
                                                        dispatch(
                                                            contactUsMail(
                                                                values
                                                            )
                                                        )
                                                            .unwrap()
                                                            .then(() => {
                                                                setContactWay(
                                                                    null
                                                                );
                                                                setValues(
                                                                    initialFormInfo
                                                                );
                                                            })
                                                            .catch(() => {
                                                                setContactWay(
                                                                    null
                                                                );
                                                            });
                                                    }}
                                                >
                                                    Enviar mensaje
                                                </Button>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </DialogContentText>
                        </DialogContent>
                    </>
                )
            ) : (
                <>
                    <div className={classes.paper}>
                        <DialogTitle id="alert-dialog-title">
                            <Typography component="h1" variant="h4">
                                ¿Como deseas contactarnos?
                            </Typography>
                        </DialogTitle>
                    </div>
                    <DialogActions className={classes.optionButtons}>
                        <ReactWhatsapp
                            className={classes.whatsButton}
                            number="+523321367629"
                            message=""
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                type="button"
                                onClick={() => {
                                    setContactWay(null);
                                    setOpen(false);
                                }}
                            >
                                <WhatsAppIcon style={{ fontSize: 50 }} />
                                whatsapp
                            </Button>
                        </ReactWhatsapp>
                        <Button
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={() => {
                                setContactWay('mail');
                            }}
                        >
                            <MailOutlineIcon style={{ fontSize: 50 }} />
                            Correo
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
