import {
    Button,
    Grid,
    Typography,
    Card,
    Container,
    CardMedia,
    CssBaseline,
    CardContent,
    CardActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteContent,
    deleteImg,
    fetchDataOfType,
    updateInfo,
    uploadImg,
    uploadInfo,
} from './dataOfTypeSlice';
import background from '../../../assets/images/background_empresa.jpg';
import { useAuth0 } from '@auth0/auth0-react';
import DialogScreen from '../../../common/DialogScreen/DialogScreen';
import UpdateDialog from '../../../common/UpdateData/UpdateDialog/UpdateDialog';
import UploadImage from '../../../common/UploadImage/UploadImage/UploadImage';
import DeleteImage from '../../../common/DeleteImage/DeleteImage';
import Footer from '../../../common/Footer/Footer';

const useStyles = makeStyles(theme => ({
    main: {
        backgroundImage: `url(${background})`,
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(20),
        paddingBottom: theme.spacing(8),
    },
    heroContent: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        padding: theme.spacing(8, 0, 6),
        placeItems: 'center',
    },
    backgroundImagen: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        width: 400,
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footer: {
        backgroundColor: 'rgba(121, 155, 157, 0.4)',
        padding: theme.spacing(6),
        color: 'inherit',
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(255,255,255,0.8)',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        flexGrow: 2,
    },
    cover: {
        width: '500px',
        height: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '300px',
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    button: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: theme.spacing(1),
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    rootMobile: {
        display: 'none',
        justifyContent: 'space-between',
        backgroundColor: 'rgba(255,255,255,0.8)',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));

const SubjectInfo = () => {
    const { isAuthenticated } = useAuth0();
    const classes = useStyles();
    const cards = useSelector(state => state.dataOfType.data);
    const { id, type } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openEditImage, setOpenEditImage] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [fileType, setFileType] = useState('');
    const [onEdit, setOnEdit] = useState({});
    const [openDS, setOpenDS] = useState(false);
    const [dataScreen, setDataScreen] = useState({});

    useEffect(() => {
        if (id) {
            dispatch(fetchDataOfType(id));
        }
    }, [dispatch, id]);

    const handleClose = () => setOpen(false);
    const handleCloseEditImage = () => setOpenEditImage(false);
    const handleCloseDelete = () => setOpenDelete(false);

    function printFile(imageFile, contentId) {
        const formData = new FormData();

        const operations = `{ "query": "mutation ($file: Upload!) { upload(imageFile: $file, contentId: ${contentId}) }", "variables": { "file": null } }`;
        //const operations = `{ "query": "mutation ($file: Upload!){ createFullContent(title: "some", description: "some", type_id: 1, files:[{imageFile: $file}]) }", "variables": { "file": null } }`;
        formData.append('operations', operations);

        const map = `{"0": ["variables.file"]}`;
        formData.append('map', map);
        formData.append('0', imageFile);
        return formData;
    }

    function filterItems(query, data) {
        const object = data.files.filter(
            file => file.path.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
        return object[0]?.path;
    }

    return (
        <>
            <CssBaseline />
            <main className={classes.main}>
                <Container className={classes.cardGrid} maxWidth="lg">
                    {isAuthenticated && (
                        <Grid className={classes.button}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpen(true)}
                            >
                                Agregar {type}
                            </Button>
                        </Grid>
                    )}
                    {/* End hero unit */}
                    <Grid
                        container
                        spacing={4}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {cards.map(card => (
                            <Grid item key={card} xs={12} sm={12} md={12}>
                                <Card className={classes.root}>
                                    {cards.indexOf(card) % 2 === 0 && (
                                        <CardMedia
                                            className={classes.cover}
                                            image={
                                                `http://api.paileriaortiz.com/` +
                                                filterItems('images', card)
                                            }
                                            title="Image title"
                                        >
                                            {typeof filterItems(
                                                'images',
                                                card
                                            ) === 'string' &&
                                                isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpenDelete(true);
                                                            setFileType(
                                                                'imagen'
                                                            );
                                                        }}
                                                    >
                                                        <DeleteForeverIcon fontSize="large" />
                                                    </Button>
                                                )}
                                        </CardMedia>
                                    )}
                                    <CardContent className={classes.content}>
                                        <div>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {card.title}
                                            </Typography>
                                            <Typography>
                                                {card.description}
                                            </Typography>
                                        </div>
                                        <div className={classes.actions}>
                                            <CardActions>
                                                {typeof filterItems(
                                                    'videos',
                                                    card
                                                ) === 'string' ? (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOpenDS(true);
                                                            setDataScreen({
                                                                ...card,
                                                            });
                                                        }}
                                                    >
                                                        Ver video
                                                    </Button>
                                                ) : (
                                                    isAuthenticated &&
                                                    type !== 'Carrusel' && (
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOnEdit({
                                                                    ...card,
                                                                });
                                                                setOpenEditImage(
                                                                    true
                                                                );
                                                                setFileType(
                                                                    'video'
                                                                );
                                                            }}
                                                        >
                                                            Agregar video
                                                        </Button>
                                                    )
                                                )}

                                                {isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                )}
                                                {typeof filterItems(
                                                    'images',
                                                    card
                                                ) !== 'string' &&
                                                    isAuthenticated && (
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOnEdit({
                                                                    ...card,
                                                                });
                                                                setOpenEditImage(
                                                                    true
                                                                );
                                                                setFileType(
                                                                    'imagen'
                                                                );
                                                            }}
                                                        >
                                                            Agregar Imagen
                                                        </Button>
                                                    )}
                                            </CardActions>
                                            <CardActions>
                                                {isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpenDelete(true);
                                                            setFileType(type);
                                                        }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                )}
                                            </CardActions>
                                        </div>
                                    </CardContent>
                                    {cards.indexOf(card) % 2 === 1 && (
                                        <CardMedia
                                            className={classes.cover}
                                            image={
                                                `http://api.paileriaortiz.com/` +
                                                filterItems('images', card)
                                            }
                                            title="Image title"
                                        >
                                            {typeof filterItems(
                                                'images',
                                                card
                                            ) === 'string' &&
                                                isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpenDelete(true);
                                                            setFileType(
                                                                'imagen'
                                                            );
                                                        }}
                                                    >
                                                        <DeleteForeverIcon fontSize="large" />
                                                    </Button>
                                                )}
                                        </CardMedia>
                                    )}
                                </Card>
                                <Card className={classes.rootMobile}>
                                    <CardMedia
                                        className={classes.cover}
                                        image={
                                            `http://api.paileriaortiz.com/` +
                                            filterItems('images', card)
                                        }
                                        title="Image title"
                                    >
                                        {typeof filterItems('images', card) ===
                                            'string' &&
                                            isAuthenticated && (
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setOnEdit({
                                                            ...card,
                                                        });
                                                        setOpenDelete(true);
                                                        setFileType('imagen');
                                                    }}
                                                >
                                                    <DeleteForeverIcon fontSize="large" />
                                                </Button>
                                            )}
                                    </CardMedia>

                                    <CardContent className={classes.content}>
                                        <div>
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {card.title}
                                            </Typography>
                                            <Typography>
                                                {card.description}
                                            </Typography>
                                        </div>
                                        <div className={classes.actions}>
                                            <CardActions>
                                                {typeof filterItems(
                                                    'videos',
                                                    card
                                                ) === 'string' ? (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOpenDS(true);
                                                            setDataScreen({
                                                                ...card,
                                                            });
                                                        }}
                                                    >
                                                        Ver video
                                                    </Button>
                                                ) : (
                                                    isAuthenticated &&
                                                    type !== 'Carrusel' && (
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOnEdit({
                                                                    ...card,
                                                                });
                                                                setOpenEditImage(
                                                                    true
                                                                );
                                                                setFileType(
                                                                    'video'
                                                                );
                                                            }}
                                                        >
                                                            Agregar video
                                                        </Button>
                                                    )
                                                )}

                                                {isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        Editar
                                                    </Button>
                                                )}
                                                {typeof filterItems(
                                                    'images',
                                                    card
                                                ) !== 'string' &&
                                                    isAuthenticated && (
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            onClick={() => {
                                                                setOnEdit({
                                                                    ...card,
                                                                });
                                                                setOpenEditImage(
                                                                    true
                                                                );
                                                                setFileType(
                                                                    'imagen'
                                                                );
                                                            }}
                                                        >
                                                            Agregar Imagen
                                                        </Button>
                                                    )}
                                            </CardActions>
                                            <CardActions>
                                                {isAuthenticated && (
                                                    <Button
                                                        size="small"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setOnEdit({
                                                                ...card,
                                                            });
                                                            setOpenDelete(true);
                                                            setFileType(type);
                                                        }}
                                                    >
                                                        Borrar
                                                    </Button>
                                                )}
                                            </CardActions>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Container className={classes.cardGrid} maxWidth="md">
                    <DialogScreen
                        isAuthenticated={isAuthenticated}
                        open={openDS}
                        data={dataScreen}
                        handleClose={() => {
                            setOpenDS(false);
                        }}
                        deleteFile={e => {
                            setOpenDS(false);
                            setOnEdit({
                                ...e,
                            });
                            setOpenDelete(true);
                            setFileType('video');
                        }}
                    />
                    <DeleteImage
                        isAllInfo={fileType === type}
                        type={fileType}
                        onEdit={onEdit}
                        handleFileChange={e => {
                            fileType !== type
                                ? dispatch(deleteImg(e.id))
                                      .unwrap()
                                      .then(() => {
                                          setOnEdit({});
                                          dispatch(fetchDataOfType(id))
                                              .unwrap()
                                              .then(() => handleCloseDelete());
                                      })
                                      .catch()
                                : dispatch(deleteContent(e.id))
                                      .unwrap()
                                      .then(() => {
                                          setOnEdit({});
                                          dispatch(fetchDataOfType(id))
                                              .unwrap()
                                              .then(() => handleCloseDelete());
                                      })
                                      .catch();
                        }}
                        files={cards}
                        handleClose={() => {
                            handleCloseDelete();
                            setOnEdit({});
                        }}
                        open={openDelete}
                    />
                    <UploadImage
                        type={fileType}
                        onEdit={onEdit}
                        handleFileChange={e => {
                            dispatch(uploadImg(printFile(e.file, e.id)))
                                .unwrap()
                                .then(() => {
                                    setOnEdit({});
                                    dispatch(fetchDataOfType(id))
                                        .unwrap()
                                        .then(() => handleCloseEditImage());
                                })
                                .catch();
                        }}
                        files={cards}
                        handleClose={() => {
                            handleCloseEditImage();
                            setOnEdit({});
                        }}
                        open={openEditImage}
                    />
                    <UpdateDialog
                        onEdit={onEdit}
                        handleFileChange={e => {
                            e.id
                                ? dispatch(updateInfo({ ...e }))
                                      .unwrap()
                                      .then(() => {
                                          setOnEdit({});
                                          handleClose();
                                          dispatch(fetchDataOfType(id));
                                      })
                                      .catch()
                                : dispatch(uploadInfo({ ...e, type: id }))
                                      .unwrap()
                                      .then(() => {
                                          handleClose();
                                          dispatch(fetchDataOfType(id));
                                      })
                                      .catch();
                        }}
                        files={cards}
                        handleClose={() => {
                            handleClose();
                            setOnEdit({});
                        }}
                        open={open}
                    />
                </Container>
                {/* Footer */}
                <Footer className={classes.footer} />
                {/* End footer */}
            </main>
        </>
    );
};

export default SubjectInfo;
