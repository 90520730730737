import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import { initialValues, validationSchema } from './dialogForm';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    miniPicture: {
        height: '100px',
        width: '100px',
    },
}));

const DeleteImage = ({
    handleClose,
    handleFileChange,
    open,
    type,
    isAllInfo = false,
    onEdit = { ...initialValues },
}) => {
    const classes = useStyles();

    function filterItems(query) {
        if (open) {
            const object = onEdit.files.filter(
                file =>
                    file.path.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
            return object[0]?.id;
        } else return null;
    }

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                Eliminar {type}
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={onEdit}
                    validationSchema={validationSchema}
                    onSubmit={() =>
                        handleFileChange({
                            id: isAllInfo
                                ? Number(onEdit.id)
                                : Number(
                                      filterItems(
                                          type === 'imagen'
                                              ? 'images'
                                              : 'videos'
                                      )
                                  ),
                        })
                    }
                >
                    {() => {
                        return (
                            <Form>
                                <Grid
                                    justifyContent="center"
                                    alignItems="center"
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Typography gutterBottom>
                                        ¿Estas seguro que deseas eliminar este
                                        contenido?
                                    </Typography>
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            onClick={handleClose}
                                            variant="outlined"
                                            type="button"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                        >
                                            Eliminar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteImage;
