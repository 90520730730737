import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import { OutlinedTextField } from '../../FormComponents/FormComponents';
import { initialValues, validationSchema } from './dialogForm';

const useStyles = makeStyles(theme => ({
    dialogActions: {
        marginBottom: theme.spacing(1),
    },
    dialogGrid: {
        marginBottom: theme.spacing(2),
    },
    dialogTitle: {
        '& > *': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    inputFile: {
        width: 0,
        height: 0,
    },
    miniPicture: {
        height: '100px',
        width: '100px',
    },
}));

const UpdateDialog = ({
    handleClose,
    handleFileChange,
    open,
    onEdit = { ...initialValues },
}) => {
    const classes = useStyles();

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                Carga de información
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={onEdit}
                    validationSchema={validationSchema}
                    onSubmit={({ title, description }) =>
                        handleFileChange({
                            title,
                            description,
                            id: onEdit?.id ?? null,
                        })
                    }
                >
                    {() => {
                        return (
                            <Form>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="title"
                                        type="text"
                                        label="Titulo"
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        edit
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogGrid}
                                    container
                                    spacing={3}
                                >
                                    <Field
                                        component={OutlinedTextField}
                                        name="description"
                                        type="text"
                                        label="Descripción"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        multiline
                                        maxRows={3}
                                        edit
                                    />
                                </Grid>
                                <Grid
                                    className={classes.dialogActions}
                                    container
                                    spacing={3}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            onClick={handleClose}
                                            variant="outlined"
                                            type="button"
                                            fullWidth
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                        >
                                            Cargar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateDialog;
