import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchTypes } from '../../features/catalogs/catalogSlice';
import { LOADING_STATUS } from '../../helpers/constants';
import ContactUs from '../../features/login/contactUs/ContactUs';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    listItem: {
        color: 'rgb(255,255,255)',
    },
    listItemActive: {
        color: 'rgb(121, 155, 157)',
    },
}));

function ResponsiveDrawer(props) {
    const catalogs = useSelector(state => state.catalogs.catalogs);
    const catalogStatus = useSelector(state => state.catalogs.status);
    const { windows, menuToggle, menuToggleHandle } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { isAuthenticated, logout } = useAuth0();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(null);

    useEffect(() => {
        dispatch(fetchTypes());
    }, [dispatch]);

    const forActive = () => {
        const actualLocation = window.location.pathname.split('/');
        return actualLocation[1];
    };

    useEffect(() => {
        setMenuActive({
            text: forActive() ?? '',
        });
    }, [setMenuActive]);

    // const searchPermit = namePermit =>
    //     permissions.find(permit => permit.name === namePermit) ? true : false;

    const drawer = (
        <div>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Menú
                    </ListSubheader>
                }
            >
                <ListItem
                    className={
                        menuActive?.text === ''
                            ? classes.listItemActive
                            : classes.listItem
                    }
                    component={Link}
                    to="/"
                    onClick={() => {
                        menuToggleHandle();
                        setMenuActive({
                            text: '',
                        });
                    }}
                    button
                >
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem
                    className={
                        menuActive?.text === 'empresa'
                            ? classes.listItemActive
                            : classes.listItem
                    }
                    component={Link}
                    to="/empresa"
                    onClick={() => {
                        menuToggleHandle();
                        setMenuActive({
                            text: 'empresa',
                        });
                    }}
                    button
                >
                    <ListItemText primary="Empresa" />
                </ListItem>
                {catalogStatus === LOADING_STATUS.SUCCEEDED &&
                    catalogs.types?.map(menu =>
                        isAuthenticated ? (
                            <ListItem
                                className={
                                    menuActive?.text === menu.text
                                        ? classes.listItemActive
                                        : classes.listItem
                                }
                                component={Link}
                                to={`/${menu.text}/${menu.id}`}
                                onClick={() => {
                                    setMenuActive({
                                        text: menu.text,
                                    });
                                    menuToggleHandle();
                                }}
                                button
                            >
                                <ListItemText primary={menu.text} />
                            </ListItem>
                        ) : (
                            menu.text !== 'Carrusel' && (
                                <ListItem
                                    className={
                                        menuActive?.text === menu.text
                                            ? classes.listItemActive
                                            : classes.listItem
                                    }
                                    component={Link}
                                    to={`/${menu.text}/${menu.id}`}
                                    onClick={() => {
                                        setMenuActive({
                                            text: menu.text,
                                        });
                                        menuToggleHandle();
                                    }}
                                    button
                                >
                                    <ListItemText primary={menu.text} />
                                </ListItem>
                            )
                        )
                    )}
                {!isAuthenticated && (
                    <ListItem
                        className={classes.listItem}
                        onClick={() => {
                            setOpen(true);
                            menuToggleHandle();
                        }}
                        button
                    >
                        <ListItemText primary="¡Contáctanos!" />
                    </ListItem>
                )}
                {isAuthenticated && (
                    <ListItem
                        className={classes.listItem}
                        component={Link}
                        onClick={() => logout()}
                        to="#"
                        button
                    >
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar sesión" />
                    </ListItem>
                )}
            </List>
        </div>
    );

    const container =
        windows !== undefined ? () => windows().document.body : undefined;

    return (
        <div className={classes.root}>
            <div className={classes.forDialog}>
                <ContactUs open={open} setOpen={e => setOpen(e)} />
            </div>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={menuToggle}
                        onClose={menuToggleHandle ? menuToggleHandle : null}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    windows: PropTypes.func,
};

export default ResponsiveDrawer;
