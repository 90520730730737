import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOADING_STATUS } from '../../../helpers/constants';
import { enqueueSnackbar } from '../../pushNotifications/pushNotificationsSlice';
import { getContentByType } from '../../../api/catalogs';

const initialState = {
    data: [],
    fetchStatus: LOADING_STATUS.IDLE,
    insertStatus: LOADING_STATUS.IDLE,
    deleteStatus: LOADING_STATUS.IDLE,

    error: null,
};

export const fetchDataOfType = createAsyncThunk(
    'subjects/fetchDataOfType',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await getContentByType(id);
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar la información.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadInfo = createAsyncThunk(
    'subjects/uploadInfo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await fetch(process.env.REACT_APP_API, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: `mutation {
                    createContent(
                        title: "${body.title}",
                        description: "${body.description}",
                        type_id: ${body.type},)
                        {
                            id
                      }
                    }`,
                }),
            });
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo agregar la información.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const updateInfo = createAsyncThunk(
    'subjects/updateInfo',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await fetch(process.env.REACT_APP_API, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: `mutation {
                        updateContent(
                        title: "${body.title}",
                        description: "${body.description}",
                        id: ${body.id},)
                        {
                            id
                      }
                    }`,
                }),
            });
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo agregar la información.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const uploadImg = createAsyncThunk(
    'subjects/uploadImg',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            const data = await fetch(process.env.REACT_APP_API, {
                method: 'POST',
                body: body,
            });
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo subir el archivo.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteImg = createAsyncThunk(
    'subjects/deleteImg',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await fetch(process.env.REACT_APP_API, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: `mutation {
                        deleteFile(id: ${id}) {
                          id
                        }
                      }`,
                }),
            });
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo eliminar el archivo.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const deleteContent = createAsyncThunk(
    'subjects/deleteContent',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const data = await fetch(process.env.REACT_APP_API, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: `mutation {
                        deleteContent(id: ${id})
                      }`,
                }),
            });
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo eliminar el contenido.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const dataOfTypeSlice = createSlice({
    name: 'dataOfType',
    initialState,
    reducers: {
        resetValues: () => {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDataOfType.pending, state => {
                state.fetchStatus = LOADING_STATUS.LOADING;
            })
            .addCase(fetchDataOfType.fulfilled, (state, { payload }) => {
                state.fetchStatus = LOADING_STATUS.SUCCEEDED;
                state.data = payload.content_type;
            })
            .addCase(fetchDataOfType.rejected, (state, { error }) => {
                state.fetchStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadInfo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadInfo.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadInfo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(updateInfo.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(updateInfo.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(updateInfo.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(uploadImg.pending, state => {
                state.insertStatus = LOADING_STATUS.LOADING;
            })
            .addCase(uploadImg.fulfilled, state => {
                state.insertStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(uploadImg.rejected, (state, { error }) => {
                state.insertStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(deleteImg.pending, state => {
                state.deleteStatus = LOADING_STATUS.LOADING;
            })
            .addCase(deleteImg.fulfilled, state => {
                state.deleteStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(deleteImg.rejected, (state, { error }) => {
                state.deleteStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(deleteContent.pending, state => {
                state.deleteStatus = LOADING_STATUS.LOADING;
            })
            .addCase(deleteContent.fulfilled, state => {
                state.deleteStatus = LOADING_STATUS.SUCCEEDED;
            })
            .addCase(deleteContent.rejected, (state, { error }) => {
                state.deleteStatus = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export const { resetValues } = dataOfTypeSlice.actions;

export default dataOfTypeSlice.reducer;
