import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function Loading() {
    const classes = useStyles();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={classes.root}>
                    <CircularProgress />
                </div>
            </div>
        </>
    );
}
