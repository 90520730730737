import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCatalogs, getCatalog, getTypes } from '../../api/catalogs';
import { LOADING_STATUS } from '../../helpers/constants';
import { decodeValue } from '../../helpers/strings';
import { enqueueSnackbar } from '../pushNotifications/pushNotificationsSlice';
import { catalogMapper } from './utils';

const initialState = {
    catalogs: {
        types: [],
        courseModality: [],
        subjectType: [],
        paymentType: [],
        paymentStatus: [],
        Roles: [],
        currency: [],
        documenttype: [],
        gender: [],
        industries: [],
        influency: [],
        jobcode: [],
        language: [],
        maritalstatus: [],
        phonetype: [],
        postalcode: [],
        state: [],
    },
    status: LOADING_STATUS.IDLE,
    error: null,
};

export const fetchTypes = createAsyncThunk(
    'catalogs/fetchTypes',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getTypes();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudieron cargar los menus.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchCatalogs = createAsyncThunk(
    'catalogs/fetchCatalogs',
    async (catalogName, { dispatch, rejectWithValue }) => {
        try {
            const data = await getCatalog(catalogName);
            return { catalogName, data };
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message: `No se pudo cargar el catálogo ${catalogName}.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

export const fetchAllCatalogs = createAsyncThunk(
    'catalogs/fetchAllCatalogs',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const data = await getAllCatalogs();
            return data;
        } catch (err) {
            dispatch(
                enqueueSnackbar({
                    message:
                        'No se pudieron cargar los catálogos, algunos campos podrían mostrarse incorrectamente.',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                    },
                })
            );
            return rejectWithValue(err.message);
        }
    }
);

const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTypes.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchTypes.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs.types = catalogMapper(payload?.types ?? []);
            })
            .addCase(fetchTypes.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchCatalogs.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchCatalogs.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs[payload.catalogName] = payload.data;
            })
            .addCase(fetchCatalogs.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            })
            .addCase(fetchAllCatalogs.pending, state => {
                state.status = LOADING_STATUS.LOADING;
            })
            .addCase(fetchAllCatalogs.fulfilled, (state, { payload }) => {
                state.status = LOADING_STATUS.SUCCEEDED;
                state.catalogs = Object.entries(payload).reduce(
                    (acc, [key, value]) => ({
                        ...acc,
                        [key.toLowerCase()]:
                            value?.length >= 0
                                ? value
                                      .filter(({ text }) => text)
                                      .map(value => ({
                                          ...value,
                                          text: decodeValue(value.text),
                                      }))
                                : value,
                    }),
                    {}
                );
            })
            .addCase(fetchAllCatalogs.rejected, (state, { error }) => {
                state.status = LOADING_STATUS.FAILED;
                state.error = error.message;
            });
    },
});

export default catalogsSlice.reducer;
