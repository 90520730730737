import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    body: {
        height: '100vh',
        backgroundColor: 'rgb(0, 0, 0)',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'fixed',
        color: 'white',
    },
    image: {
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogScreen({
    open = false,
    handleClose,
    data = {},
    deleteFile,
    isAuthenticated,
}) {
    function filterItems(query) {
        if (open) {
            const object = data.files.filter(
                file =>
                    file.path.toLowerCase().indexOf(query.toLowerCase()) > -1
            );
            return object[0]?.path;
        } else return null;
    }

    const classes = useStyles();

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {data.title}
                    </Typography>
                    {isAuthenticated && (
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={() => deleteFile(data)}
                        >
                            Eliminar
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <div className={classes.body}>
                <CardMedia
                    autoPlay
                    controls
                    src={
                        `http://api.paileriaortiz.com/` + filterItems('videos')
                    }
                    component="video"
                    className={classes.image}
                    // poster={
                    //     `http://api.paileriaortiz.com/` + filterItems('images')
                    // }
                />
            </div>
        </Dialog>
    );
}
