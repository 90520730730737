import React from 'react';
import { Paper, makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    image: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '90vh',
    },
    paper: {
        margin: theme.spacing(5, 4),
        padding: theme.spacing(5, 4, 1),
        position: 'absolute',
        top: '100px',
        backgroundColor: 'rgba(255,255,255,1)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '90vh',
        color: 'white',
    },
}));

export default function Item({ item }) {
    function filterItems(query, data) {
        const object = data.files.filter(
            file => file.path.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
        return object[0]?.path;
    }
    const classes = useStyles();
    return (
        <Paper
            className={classes.image}
            style={{
                backgroundImage: `url(http://api.paileriaortiz.com/${filterItems(
                    'images',
                    item
                )})`,
            }}
        >
            <Grid className={classes.container}>
                <Grid>
                    <Grid container justifyContent="center">
                        <Grid Item>
                            <Typography
                                variant="h3"
                                align="center"
                                color="inherit"
                            >
                                {item.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography variant="h4" align="center" color="inherit">
                    {item.description}
                </Typography>
            </Grid>
        </Paper>
    );
}
