import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import background from '../../../assets/images/background_empresa.jpg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: '0px 50px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '90px 0px',
        },
    },
    body: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'fixed',
        color: 'white',
    },
    image: {
        backgroundImage: `url(${background})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            height: '400px',
            overflow: 'scroll',
        },
    },
    avatar: {
        width: '70px',
    },
    form: {
        width: '90%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '60%',
        fontSize: '13px',
        backgroundColor: 'rgb(121, 155, 157)',
    },
    login: {
        backgroundColor: 'rgba(255,255,255,0.0)',
        boxShadow: 'none',
    },
    menus: {
        display: 'flex',
        marginRight: '30px',
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    logo: {
        height: '50px',
        margin: '0 10px 0 10px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            marginRight: '20px',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    aButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
    },
    aButtonW: {
        color: 'rgba(255, 255, 255, 1)',
        textDecoration: 'none',
    },
    checkAvatar: {
        height: '70px',
        width: '70px',
        color: 'green',
    },
    info: {
        textAlign: 'center',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            height: '400px',
        },
    },
    itemInfo: {
        [theme.breakpoints.down('sm')]: {
            margin: '50px 5px',
            height: '400px',
        },
    },
    itemTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
        },
    },
}));

export default function Company() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.body}>
                <Grid
                    container
                    component="main"
                    className={classes.image}
                    justifyContent="flex-end"
                >
                    <CssBaseline />
                    <Grid
                        container
                        justifyContent="center"
                        alignContent="center"
                        className={classes.root}
                        spacing={9}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                align="center"
                                color="inherit"
                                className={classes.itemTitle}
                            >
                                Nuestra empresa
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.container}>
                            <Grid
                                container
                                alignContent="center"
                                justifyContent="center"
                                spacing={9}
                                className={classes.paper}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    spacing={9}
                                    className={classes.itemInfo}
                                >
                                    <Grid>
                                        <Grid container justifyContent="center">
                                            <Grid Item>
                                                <TrackChangesIcon
                                                    style={{ fontSize: 40 }}
                                                />
                                            </Grid>
                                            <Grid Item>
                                                <Typography
                                                    variant="h4"
                                                    align="center"
                                                    color="inherit"
                                                >
                                                    Misión
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        color="inherit"
                                    >
                                        Satisfacer las necesidades de nuestros
                                        clientes, ofreciendo servicios de
                                        calidad con precios competitivos en el
                                        mercado; con el único objetivo de que
                                        nuestros clientes puedan agilizar el
                                        proceso de sus ventas y crear una
                                        confianza en ellos. Nuestro objetivo es
                                        trabajar mano a mano formando un vinculo
                                        cliente - proveedor.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    spacing={9}
                                    className={classes.itemInfo}
                                >
                                    <Grid container justifyContent="center">
                                        <Grid Item>
                                            <VisibilityOutlinedIcon
                                                style={{ fontSize: 40 }}
                                            />
                                        </Grid>
                                        <Grid Item>
                                            <Typography
                                                variant="h4"
                                                align="center"
                                                color="inherit"
                                            >
                                                Vision
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        color="inherit"
                                    >
                                        Satisfacer las necesidades de todos
                                        nuestros clientes ofreciendo servicios
                                        de calidad, con precios competitivos en
                                        el mercado, con el único objetivo de que
                                        nuestros clientes puedan agilizar el
                                        proceso de sus ventas y crear una
                                        confianza en nuestros clientes ya que
                                        nuestra misión es trabajar de la mano
                                        cliente proveedor
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
